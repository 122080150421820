import styles from './Login.module.css'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'



const Login = () => {

    const initialValues = {
        email: "",
        password: "",
    };

    const [adminInfo, setAdminInfo] = useState(initialValues)
    const navigate = useNavigate();
    const [name, setName] = useState('')

    useEffect(() => {
        setName(process.env.REACT_APP_API_LOGIN_NAME);
        document.title = process.env.REACT_APP_API_HEAD_NAME;
    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target

        setAdminInfo({
            ...adminInfo,
            [name]: value,
        })


    }

    const signin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/login`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(adminInfo)
            });

            const data = await response.json();


            if (data.token) {
                localStorage.setItem('token', JSON.stringify(data.token));
                localStorage.setItem('name', JSON.stringify(data.userName));
                localStorage.setItem('lastName', JSON.stringify(data.userLastName));
                localStorage.setItem('email', JSON.stringify(data.userEmail));

                navigate(`/admin/layout/tags`);
            }
        } catch (err) {
            console.log(err);
        }
    }



    return (
        <div className={styles.login}>
            <h1>{name}</h1>
            <form onSubmit={signin}>
                <input onChange={handleChange} value={adminInfo.email} type="email" name="email" placeholder="email" required="required" />
                <input onChange={handleChange} value={adminInfo.password} type="password" name="password" autoComplete='on' placeholder="Password" required="required" />
                <button type="submit" className={styles.btn}>Login</button>
            </form>
            <div>
            </div>
        </div>

    )
}

export default Login