import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line, ResponsiveContainer, BarChart, Bar } from "recharts";


const Report = () => {

    const navigate = useNavigate();
    const [cost, setCost] = useState();
    const [impressions, setImpressions] = useState();
    const [views, setViews] = useState();
    const [fillRate, setFillRate] = useState();
    const [graphData, setGraphData] = useState();
    // const [tableData, setTableData] = useState();
    const [loading, setLoading] = useState(true);
    const [publisher, setPublisher] = useState('Peak');
    const [date1, setDate1] = useState('');
    const [date2, setDate2] = useState('');


    useEffect(() => {

        const fetchData = async () => {
            let today = new Date();
            let beforeSevenDays = new Date();
            beforeSevenDays.setDate(today.getDate() - 7);
            const formattedToday = moment(today).format("YYYY-MM-DD");
            const formattedBeforeSevenDays = moment(beforeSevenDays).format("YYYY-MM-DD");

            const data = await httpFetchFunction(formattedBeforeSevenDays, formattedToday)
            if(data){
                dataOrganizer(data)
            }
    
        }
        fetchData()

    }, [])




    const handleClick = async () => {
        setLoading(true)
        const fromDate = moment(date1.$d).format('YYYY-MM-DD');
        const toDate = moment(date2.$d).format('YYYY-MM-DD');


        try {
            const data = await httpFetchFunction(fromDate, toDate)

            if (data) {
                dataOrganizer(data);
            }


        } catch (err) {
            console.log(err);
        }
    }

    const httpFetchFunction = async (fromDate, toDate) => {

        const dates = `${fromDate},${toDate},${publisher}`

        try {

            const res = await fetch(`${process.env.REACT_APP_API_URL}/report/${dates}`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            return data
            
        } catch (err) {
            console.log(err);
        }

    }


    const dataOrganizer = (data) => {

        if (data) {
            if (data.peakResponse && data.lqdmResponse) {
                const lqdm = lqdmOrganizer(data.lqdmResponse.rows);
                const peak = peakOrganizer(data.peakResponse.data);
                setImpressions((lqdm.impressions + peak.impressions).toLocaleString());
                setViews((lqdm.views + peak.views).toLocaleString());
                setCost(((lqdm.cost + peak.cost).toFixed(2)));
                const fillImps = lqdm.impressions + peak.impressions;
                const fillViews = lqdm.views + peak.views;
                const fillRate = fillRateFunction(fillImps, fillViews);
                setFillRate(fillRate.toFixed(2));
                const finalGraphData = peak.results.concat(lqdm.results);
                const values = reducerFunction(finalGraphData);
                setGraphData(values);
                setLoading(false);
            }
            if (data.peakResponse && !data.lqdmResponse) {
                const peak = peakOrganizer(data.peakResponse.data);
                setImpressions(peak.impressions.toLocaleString());
                setViews(peak.views.toLocaleString());
                setCost(peak.cost.toFixed(2));
                const fillRate = fillRateFunction(peak.impressions, peak.views);
                setFillRate(fillRate.toFixed(2));
                setGraphData(peak.results);
                setLoading(false);
            }
            if (!data.peakResponse && data.lqdmResponse) {
                const lqdm = lqdmOrganizer(data.lqdmResponse.rows);
                setImpressions(lqdm.impressions.toLocaleString());
                setViews(lqdm.views.toLocaleString());
                setCost(lqdm.cost.toFixed(2));
                const fillRate = fillRateFunction(lqdm.impressions, lqdm.views);
                setFillRate(fillRate.toFixed(2));
                setGraphData(lqdm.results);
                setLoading(false);
            }
        }

    }



    const peakOrganizer = (data) => {

        let cost = 0;
        let impressions = 0;
        let views = 0;
        let graphData = [];
        const peakData = data;
        peakData.shift()
        let count = 0
        peakData.forEach((campaign) => {
            cost += campaign[6];
            impressions += campaign[9]
            views += campaign[10]
            let fillRate = campaign[10] / campaign[9]
            graphData.push({ name: campaign[2], impressions: campaign[9], views: campaign[10], cost: campaign[6], fillRate: fillRate, tagId: campaign[5], id: count })
            count++;
        })


        const results = reducerFunction(graphData);

        // const tableReduced = reducerFunction(graphData)

        // setTableData(graphData)

        return { results, cost, impressions, views }

    }

    const lqdmOrganizer = (data) => {

        let results = [];
        let impressions = 0
        let views = 0
        let cost = 0
        data.forEach((campaign) => {
            cost += campaign.earnings.value
            impressions += campaign.ais.value
            views += campaign.downloads.value
            let fillRate = campaign.downloads.value / campaign.ais.value
            results.push({ name: moment(campaign.timestamp.value).format('YYYY-MM-DD'), impressions: campaign.ais.value, views: campaign.downloads.value, cost: campaign.earnings.value, fillRate: fillRate })

        })

        return { results, cost, impressions, views }

    }

    const fillRateFunction = (impressions, views) => {

        const fillRate = (views / impressions) * 100
        return fillRate
    }

    const reducerFunction = (data) => {

        let results = Object.values(data.reduce(function (acc, obj) {
            var key = obj.name;
            if (!acc[key]) {
                acc[key] = { name: key, impressions: 0, views: 0, cost: 0, fillRate: 0 };
            }
            acc[key].impressions += obj.impressions;
            acc[key].views += obj.views;
            acc[key].cost += obj.cost;
            acc[key].fillRate = acc[key].views / acc[key].impressions;
            return acc;
        }, {}));

        results.sort(function (a, b) {
            return new Date(a.name) - new Date(b.name);
        });

        return results
    }


    const handleChange = (event) => {
        setPublisher(event.target.value);
    }



    return (
        <div>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="From"
                        value={date1}
                        onChange={(newValue) => {
                            setDate1(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <span style={{ display: 'inline-block', width: '10px' }}></span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="To"
                        value={date2}
                        onChange={(newValue) => {
                            setDate2(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <span style={{ display: 'inline-block', width: '10px' }}></span>
                <Box sx={{ minWidth: 250, maxWidth: 500, display: 'inline-block' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Publishers</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={publisher}
                            label="publisher"
                            onChange={handleChange}
                            defaultValue={'All'}
                        >
                            <MenuItem value={'Peak'}>Peak</MenuItem>
                            <MenuItem value={'Lqdm'}>Lqdm</MenuItem>
                            <MenuItem value={'All'}>All</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <span style={{ display: 'inline-block', width: '10px' }}></span>
            <Button
                variant="contained"
                onClick={handleClick}
            >
                Report
            </Button>
            <br></br>
            {loading ? <LinearProgress sx={{display:'flex', alignSelf:'center'}} /> :
                <div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Card sx={{ maxWidth: 250, display: 'inline-block', marginRight: '10px' }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                impressions
                            </Typography>
                            <Typography variant="h5" component="div">
                                {impressions}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 250, display: 'inline-block', marginRight: '10px' }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Views
                            </Typography>
                            <Typography variant="h5" component="div">
                                {views}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 200, display: 'inline-block', marginRight: '10px' }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Cost
                            </Typography>
                            <Typography variant="h5" component="div">
                                {cost}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 250, display: 'inline-block', marginRight: '10px' }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                FillRate
                            </Typography>
                            <Typography variant="h5" component="div">
                                {fillRate + ' %'}
                            </Typography>
                        </CardContent>
                    </Card>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div>
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                            Impressions vs Views
                        </Typography>
                        <br></br>
                        <ResponsiveContainer className="chart-container" aspect={5}>
                            <LineChart width={1000} height={300} data={graphData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="impressions" stroke="#8884d8" activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="views" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <div>
                        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                            Cost
                        </Typography>
                        <br></br>
                        <ResponsiveContainer className="chart-container" aspect={5}>
                            <LineChart width={1000} height={300} data={graphData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="cost" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Typography sx={{ fontSize: 20, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Fill Rate
                    </Typography>
                    <br></br>
                    <div>
                        <ResponsiveContainer className="chart-container" aspect={6.5}>
                            <BarChart
                                width={500}
                                height={300}
                                data={graphData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="fillRate" formatter={(fillRate) => fillRate * 100 + '%'} fill="#8884d8" />
                                {/* <Bar dataKey="views" fill="#82ca9d" /> */}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    {/* <br></br>
                    <br></br>
                    <br></br>
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>date </TableCell>
                                        <TableCell align="right">impressions</TableCell>
                                        <TableCell align="right">views</TableCell>
                                        <TableCell align="right">cost</TableCell>
                                        <TableCell align="right">TagId</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="right">{row.impressions}</TableCell>
                                            <TableCell align="right">{row.views}</TableCell>
                                            <TableCell align="right">{row.cost}</TableCell>
                                            <TableCell align="right">{row.tagId}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div> */}
                </div>
            }
        </div>
    )

}

export default Report