import { useState } from "react";
import styles from './DuplicatorEvernetix.module.css'
import { useNavigate } from 'react-router-dom'


const DuplicatorEvernetix = () =>{

    const initialValues = {
        campaignId: "",
        numberOfClones: "",
        minRate: "",
        maxRate: "",
    };

    const [campaign, setCampaign] = useState(initialValues);
    const [id, setId] = useState('');
    const [message, setMessage] = useState('');
    const [submitResponse, setSubmitResponse] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaign({
            ...campaign,
            [name]: value,
        });

    };

    const sendInfo = async (e) => {
        e.preventDefault();

        if (campaign) {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/createCampaigns/evernetix`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(campaign) // body data type must match "Content-Type" header
            });

            if (res.status === 403) {
                navigate("/");
            }

            const data = await res.json();
            
            if (data.successful) {
                setSubmitResponse('Successful');
                setTimeout(() => {
                    setSubmitResponse('');
                    setMessage('');
                    setId('');
                    setCampaign(initialValues);
                }, 2000);
            } else {
                setSubmitResponse('Error: fill out the form again');
            }
        }
    }

    const validate = async (e) => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/validation/evernetix`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({ id }) // body data type must match "Content-Type" header
        });

        if (res.status === 403) {
            navigate("/");
        }

        const data = await res.json();
        if (data.response) {
            setMessage('Valid');
        }
        if (data.error) {
            setMessage('Invalid');
        }
    }

    const validator = (e) => {
        setId(e.target.value);
    }

    return (
        <div className={styles['duplicator-body']}>
            <form onSubmit={sendInfo} className={styles["form"]}>
                <div className={styles.title}>{process.env.REACT_APP_API_NAME}</div>
                <div className={styles.subtitle}>Evernetix Campaign Creator</div>
                <hr></hr>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button className={styles.validate} onClick={validate}>validate</button>
                        <input className={styles['input-validate']} value={id} name="id" onChange={validator}></input>
                        <span className={styles['span-validator']}>{message}</span>
                    </div>
                </div>
                <div className={`${styles['input-container']} ${styles['ic1']} `}>
                    <input required value={campaign.campaignId} name="campaignId" type='number' onChange={handleChange} id="campaignId" className={styles["input"]} placeholder=" " />
                    <div className={styles.cut}></div>
                    <label htmlFor="campaignId" className={styles.placeholder}>Campaign ID</label>
                </div>
                <div className={`${styles['input-container']} ${styles['ic2']} `}>
                    <input required min='1' max='30' value={campaign.numberOfClones} name="numberOfClones" type='number' onChange={handleChange} id="numberOfClones" className={styles["input"]} placeholder=" " />
                    <div className={styles.cut}></div>
                    <label htmlFor="numberOfClones" className={styles.placeholder}>Number of Clones</label>
                </div>
                <div className={`${styles['input-container']} ${styles['ic2']} `}>
                    <input required value={campaign.minRate} name="minRate" type='number' onChange={handleChange} id="minRate" className={styles["input"]} placeholder=" " />
                    <div className={`${styles['cut']} ${styles['cut-short']} `}></div>
                    <label htmlFor="minimumRate" className={styles.placeholder}>Minimum Rate</label>
                </div>
                <div className={`${styles['input-container']} ${styles['ic2']} `}>
                    <input min='1' step="any" required value={campaign.maxRate} name="maxRate" type='number' onChange={handleChange} id="maxRate" className={styles["input"]} placeholder=" " />
                    <div className={`${styles['cut']} ${styles['cut-short']} `}></div>
                    <label htmlFor="maximumRate" className={styles.placeholder}>Maximum Rate</label>
                </div>
                <button type="text" className={styles.submit}>submit</button>
                <div className={styles.submitResponse}>
                    <span>{submitResponse}</span>
                </div>
            </form>
        </div>
    );  
}

export default DuplicatorEvernetix;