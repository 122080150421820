import styles from './DeleteTag.module.css'
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';


const DeleteTag = (props) => {
    const navigate = useNavigate();

    const deleteTag = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/delete/tag/${props.id}`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            if (res.ok) {
                props.onClose();
            }

        } catch (err) {
            console.log(err);
        }


    }

    const backToLayOut = () => {
        props.onClose();
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={true}
        >
            <Card sx={{ maxWidth: 600, maxHeight: 400, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <CardHeader
                    action={
                        <IconButton sx={{ position: 'relative', left: '15%' }} onClick={backToLayOut}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title="Are you sure you want to delete this Tag?"
                    sx={{ color: '#289FD2' }}
                />
                <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                    <button className={styles.submitButton} onClick={deleteTag}>Ok</button>
                </CardActions>
            </Card>
        </Backdrop>
    )
}

export default DeleteTag