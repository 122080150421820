import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from './Publishers.module.css'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NewPublisher from "../NewPublisher/NewPublisher";
import LinearProgress from '@mui/material/LinearProgress';

const Publishers = () => {

    const navigate = useNavigate();
    const [publishers, setPublishers] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/publishers/`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            if(data){
            setLoading(false);    
            setPublishers(data);
            }

        }
        )()

    }, [open])

    const addPublisher = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <div className={styles.wrapper} >
            <button onClick={addPublisher} className={styles.addPublisher}>New Publisher</button>
            <div className={styles.tableWrapper}>
                { loading ? <LinearProgress /> :
                    <table className={styles.table}>
                        <thead className={styles.tableRowHeader}>
                            <tr>
                                <th className={styles.tableRowHeader1}>ID</th>
                                <th>Name</th>
                                <th>Created At</th>
                                <th className={styles.tableRowHeader2}>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {publishers.map((publisher) => {
                                return (<tr key={publisher.id}>
                                    <td>{publisher.id}</td>
                                    <td>{publisher.name}</td>
                                    <td>{publisher.createdAt}</td>
                                    <td>{publisher.updatedAt}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.15)" } }}
            >
                <DialogTitle id="alert-dialog-title"></DialogTitle>
                <DialogContent>
                    <NewPublisher
                        onClose={handleClose}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )

}

export default Publishers