import styles from './TagCreator.module.css'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';



const TagCreator = (props) => {

    const [publisherId, setPublisherId] = useState('');
    const [tagName, setTagName] = useState('');
    const [bannerWidth, setBannerWidth] = useState('');
    const [bannerHeight, setBannerHeight] = useState('');
    const [speed, setSpeed] = useState('');
    const [photo, setPhoto] = useState('');
    const [link, setLink] = useState('');
    const [platform, setPlatform] = useState([]);
    const [platformName, setPlatformName] = useState('');
    const [selectError, setSelectError] = useState(false);
    const [publisherIdError, setPublisherIdError] = useState(false);
    const [tagNameError, setTagNameError] = useState(false);
    const [bannerWidthError, setBannerWidthError] = useState(false);
    const [bannerHeightError, setBannerHeightError] = useState(false);
    const [speedError, setSpeedError] = useState(false);
    const [photoError, setPhotoError] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {

        (async () => {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/platforms`, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
            });
            if (res.status === 403) {
                navigate("/");
            }
            const data = await res.json();
            setPlatform(data)
        }
        )()


    }, [setPlatform])

    const save = async () => {

        const error = await errHandling();

        if (error) {
            return
        } else {

            const finalTag = {
                name: platformName,
                publisherId: publisherId,
                description: tagName,
                width: bannerWidth,
                height: bannerHeight,
                speed,
                photo,
                link
            }

            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/add/tag`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(finalTag)
                });
                if (res.status === 403) {
                    navigate("/");
                }

                const data = await res.json();
                props.onClose();



            } catch (err) {
                console.log(err);
            }
        }
    }


    const errHandling = async () => {

        let error = false
        if (platformName === '') {
            setSelectError(true);
            error = true;
        }
        if (publisherId === '') {
            setPublisherIdError(true);
            error = true;
        }
        if (tagName === '') {
            setTagNameError(true);
            error = true;
        }
        if (bannerWidth === '') {
            setBannerWidthError(true);
            error = true;
        }
        if (bannerHeight === '') {
            setBannerHeightError(true);
            error = true;
        }
        if (speed === '') {
            setSpeedError(true);
            error = true;
        }
        if (photo === '') {
            setPhotoError(true);
            error = true;
        }
        if (link === '') {
            setLinkError(true);
            error = true;
        }
        return error;
    }



    const handleName = (e) => {
        const name = e.target.value;
        if (name !== "") {
            setSelectError(false)
        }

        setPlatformName(name);
    }


    const backToLayOut = () => {
        props.onClose();
    }

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, }}
            open={true}
        >
            <Card sx={{ maxWidth: 500, maxHeight: 750, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <CardHeader
                    action={
                        <IconButton sx={{ position: 'relative', left: '180%' }} onClick={backToLayOut}>
                            <CloseIcon />
                        </IconButton>
                    }
                    title="Add New Tag"
                    sx={{ color: '#289FD2' }}
                />
                <CardContent sx={{ width: "inherit" }} >
                    <Box
                        sx={{
                            '& > :not(style)': { m: 1, width: '50ch' },

                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Platform Name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={platformName}
                                label="Platform Name"
                                onChange={handleName}
                                error={selectError}
                            >
                                {platform.map((platform) => {
                                    return (<MenuItem key={platform.id} value={platform.name}>{platform.name}</MenuItem>)
                                })}


                            </Select>
                        </FormControl>
                        <TextField required error={publisherIdError} type='number' name='publisherId' onChange={(e) => { setPublisherId(e.target.value); e.target.value !== "" ? setPublisherIdError(false) : setPublisherIdError(true) }} id="outlined-basic" label="Adevrtiser ID" variant="outlined" />
                        <TextField required error={tagNameError} type='text' name='description' onChange={(e) => { setTagName(e.target.value); e.target.value !== "" ? setTagNameError(false) : setTagNameError(true) }} id="outlined-basic" label="Tag Name" variant="outlined" />
                        <TextField required error={bannerWidthError} type='text' name='bannerWidth' onChange={(e) => { setBannerWidth(e.target.value); e.target.value !== "" ? setBannerWidthError(false) : setBannerWidthError(true) }} id="outlined-basic" label="Banner Width" variant="outlined" />
                        <TextField required error={bannerHeightError} type='text' name='bannerHeight' onChange={(e) => { setBannerHeight(e.target.value); e.target.value !== "" ? setBannerHeightError(false) : setBannerHeightError(true) }} id="outlined-basic" label="Banner Height" variant="outlined" />
                        <TextField required error={speedError} type='text' name='speed' onChange={(e) => { setSpeed(e.target.value); e.target.value !== "" ? setSpeedError(false) : setSpeedError(true) }} id="outlined-basic" label="Speed" variant="outlined" />
                        <TextField required error={photoError} type='text' name='photo' onChange={(e) => { setPhoto(e.target.value); e.target.value !== "" ? setPhotoError(false) : setPhotoError(true) }} id="outlined-basic" label="photo" variant="outlined" />
                        <TextField required error={linkError} type='text' name='link' onChange={(e) => { setLink(e.target.value); e.target.value !== "" ? setLinkError(false) : setLinkError(true) }} id="outlined-basic" label="link" variant="outlined" />
                    </Box>
                </CardContent>
                <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                    <button onClick={save} className={styles.submitButton} type="submit">save</button>
                </CardActions>
            </Card>
        </Backdrop>
    )

}

export default TagCreator