import { useState,useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom'


const PrivateRoutes = (props) => {

    // console.log(props);
    // const [auth,setAuth] = useState(true);
    

    // useEffect(() => {
    //     async function verifying() {
    //         await tokenAuthentocation();
    //     }
    //     verifying()
    // }, [auth,setAuth])


    // const tokenAuthentocation = async () => {
    //     const verification = await fetch('http://localhost:5700/verify', {
    //         mode: 'cors',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'authorization': 'Bearer ' + localStorage.getItem('token')
    //         },


    //     });
    //     console.log(verification);
    //     if (verification.status === 403) {
    //         setAuth(false);
    //     }
    // }


    return (
        true ? <Outlet /> : <Navigate to="/" />
    )
}

export default PrivateRoutes