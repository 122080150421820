import styles from '../Callbacks/Callbacks.module.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';


const UpdateCallback = (props) => {
    const [event, setEvent] = useState('');
    const [url, setUrl] = useState('');
    const [id, setId] = useState(props.id);
    const navigate = useNavigate();


    useEffect(() => {
        (async () => {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/get/callback/${props.id}`, {
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                });
                if (res.status === 403) {
                    navigate("/");
                }
                const data = await res.json();
                setUrl(data.url);
            } catch (err) {
                console.error(err);
            }
        }
        )()


    }, [])



    const save = async (e) => {
        e.preventDefault();
        const callback = { id: id, url: url };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/update/callback`, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(callback)
            });

            const data = await response.json();
            props.onClose();

        } catch (err) {
            console.log(err);
        }
    }


    const backToLayOut = () => {
        props.onClose()
    }


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <Card sx={{ width: 500, height: 400, borderRadius: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <CardHeader
                        action={
                            <IconButton sx={{ position: 'relative', left: '200%' }} onClick={backToLayOut}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title="Update Callback"
                        sx={{ color: '#289FD2' }}
                    />
                    <CardContent sx={{ width: "inherit" }} >

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label"></InputLabel>

                            <TextField value={url} type='text' onChange={(e) => { setUrl(e.target.value) }} id="outlined-basic" label="Callback Url" variant="outlined" />
                        </FormControl>

                    </CardContent>
                    <CardActions sx={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
                        <button className={styles.submitButton} onClick={save}>save</button>
                    </CardActions>
                </Card >
            </Backdrop >
        </div >
    )

}

export default UpdateCallback;